<template lang="pug">

div
  v-btn(text color="orange" @click="$router.go(-1)")
      v-icon(left) keyboard_backspace
      | Back
  v-card
    v-card-title(primary-title)
      div
        h3.headline.mb-0 {{category.name}}
    v-card-actions
      v-btn(text color="red" @click="deleteCategory(category)")
        v-icon(left) delete_sweep
        | Delete
  br
  v-card
    v-card-title
      form
        v-text-field(v-model="category.name" label="Name" type="text")
    v-card-actions
      v-btn(color="primary" @click="saveEdit") Save Edit
  v-snackbar(v-model="snackbar" right top) Description copied to clipboard.
    v-btn(color="pink" text @click="snackbar = false") Close
  v-snackbar(v-model="snackbarsaved" right top) Edits Saved
    v-btn(color="pink" text @click="snackbarsaved = false") Close
</template>
<script>

export default {
  name: 'app',
  data () {
    return {
      snackbar: false,
      snackbarsaved: false
    }
  },
  methods: {
    deleteCategory (category) {
      const lastchance = confirm('Are you sure you want to delete the category ' + category.name + '? This cannot be undone.')
      if (lastchance) {
        this.$store.dispatch('deleteCategory', category.id)
        this.$router.go(-1)
      }
    },
    saveEdit () {
      const newCategory = {
        name: this.category.name || '',
        id: this.category.id,
        order: this.category.order
      }
      this.snackbarsaved = true
      this.$store.dispatch('updateCategory', newCategory)
    }
  },
  computed: {
    category () {
      return this.$store.getters.getCategoryById(this.$route.params.id)
    }
  }
}
</script>
<style lang="stylus" scoped>

span
  white-space pre

</style>
